var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"rounded":"sm","show":_vm.loading}},[_c('AuthWrapper',{scopedSlots:_vm._u([{key:"card",fn:function(){return [_c('div',{staticClass:"text-center"},[_c('div',[_c('h1',[_vm._v("Final Step")])]),_c('div',{staticClass:"my-2"},[_c('p',[_vm._v("Please provide the information below")])])]),_c('b-alert',{attrs:{"variant":"danger","show":""}},[(_vm.errorMessage)?_c('div',{staticClass:"alert-body font-small-2"},[_c('b-card-text',[_c('feather-icon',{attrs:{"icon":"AlertCircleIcon"}}),_c('small',[_vm._v(_vm._s(_vm.errorMessage))])],1)],1):_vm._e()]),_c('div',[_c('validation-observer',{ref:"residentialForm"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.saveOnboardingInfo($event)}}},[_c('b-media',{staticClass:"flex-column flex-md-row",attrs:{"no-body":"","vertical-align":"center"}},[_c('b-media-body',[_c('div',{staticClass:"d-inline-block"},[_c('validation-provider',{attrs:{"name":"Logo Image","rules":{
                      required: false,
                      size: 5000,
                    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Company Logo","label-for":"logo-file"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v(" Company Logo "),_c('b-card-text',{staticClass:"my-50"}),_c('small',{staticClass:"text-muted"},[_vm._v("Image size limit 5mb.")])],1)]},proxy:true}],null,true)},[_c('b-form-file',{ref:"refInputEl",attrs:{"id":"logo-file","accept":".jpg, .png, .gif","placeholder":"Choose file","state":errors.length > 0 ? false : null},on:{"input":_vm.onImageRenderer},model:{value:(_vm.companyLogo),callback:function ($$v) {_vm.companyLogo=$$v},expression:"companyLogo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)]),(_vm.companyLogoPath)?_c('b-media-aside',[_c('b-img',{attrs:{"src":_vm.companyLogoPath,"height":"120","width":"120"}})],1):_vm._e()],1),_c('b-form-group',{attrs:{"label":"Company Name","label-for":"company_name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Company Name"),_c('span',{staticStyle:{"color":"tomato"}},[_vm._v("*")])])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"company_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('div',{staticClass:"exxtra-input"},[_c('span',{staticClass:"leading"},[_c('feather-icon',{attrs:{"icon":"BriefcaseIcon","size":"20"}})],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.company_name),expression:"company_name"}],attrs:{"id":"company_name","placeholder":"Enter your Company Name Here"},domProps:{"value":(_vm.company_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.company_name=$event.target.value}}})]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"First Name","label-for":"first_name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("First Name"),_c('span',{staticStyle:{"color":"tomato"}},[_vm._v("*")])])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"first_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('div',{staticClass:"exxtra-input"},[_c('span',{staticClass:"leading"},[_c('feather-icon',{attrs:{"icon":"UserIcon","size":"20"}})],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.first_name),expression:"first_name"}],attrs:{"id":"first_name","placeholder":"Enter your First Name Here"},domProps:{"value":(_vm.first_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.first_name=$event.target.value}}})]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Last Name","label-for":"last_name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Last Name"),_c('span',{staticStyle:{"color":"tomato"}},[_vm._v("*")])])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"last_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('div',{staticClass:"exxtra-input"},[_c('span',{staticClass:"leading"},[_c('feather-icon',{attrs:{"icon":"UserIcon","size":"20"}})],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.last_name),expression:"last_name"}],attrs:{"id":"last_name","placeholder":"Enter your Last Name Here"},domProps:{"value":(_vm.last_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.last_name=$event.target.value}}})]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"mt-2",attrs:{"label":"Gender","label-for":"gender"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Gender"),_c('span',{staticStyle:{"color":"tomato"}},[_vm._v("*")])])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"gender","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('div',{staticClass:"exxtra-input"},[_c('span',{staticClass:"leading"},[_c('feather-icon',{attrs:{"icon":"HelpCircleIcon","size":"20"}})],1),_c('b-form-select',{staticStyle:{"height":"3.3rem"},attrs:{"id":"gender","options":_vm.genderOptions},model:{value:(_vm.gender),callback:function ($$v) {_vm.gender=$$v},expression:"gender"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Date of Birth","label-for":"date_of_birth"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Date of Birth"),_c('span',{staticStyle:{"color":"tomato"}},[_vm._v("*")])])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"date_of_birth","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('div',{staticClass:"exxtra-input"},[_c('span',{staticClass:"leading"},[_c('feather-icon',{attrs:{"icon":"CalendarIcon","size":"20"}})],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.date_of_birth),expression:"date_of_birth"}],attrs:{"id":"date_of_birth","placeholder":"Enter your Date of birth Here","type":"date"},domProps:{"value":(_vm.date_of_birth)},on:{"input":function($event){if($event.target.composing){ return; }_vm.date_of_birth=$event.target.value}}})]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"TIN","label-for":"tin"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("TIN"),_c('span',{staticStyle:{"color":"tomato"}},[_vm._v("*")])])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"tin","rules":"required|length:15"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('div',{staticClass:"exxtra-input"},[_c('span',{staticClass:"leading"},[_c('feather-icon',{attrs:{"icon":"ClipboardIcon","size":"20"}})],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tin),expression:"tin"}],attrs:{"id":"tin","placeholder":"Enter your TIN Here"},domProps:{"value":(_vm.tin)},on:{"input":function($event){if($event.target.composing){ return; }_vm.tin=$event.target.value}}})]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Country","label-for":"country"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Country"),_c('span',{staticStyle:{"color":"tomato"}},[_vm._v("*")])])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"country","loading":_vm.loading,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.countryOptions,"reduce":function (val) { return val.value; },"clearable":false},model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Region/State/Province","label-for":"region"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Region/State/Province"),_c('span',{staticStyle:{"color":"tomato"}},[_vm._v("*")])])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"region","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"region","loading":_vm.loading,"clearable":false,"options":_vm.regionOptions,"reduce":function (val) { return val.value; },"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.region),callback:function ($$v) {_vm.region=$$v},expression:"region"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"City","label-for":"city"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("City"),_c('span',{staticStyle:{"color":"tomato"}},[_vm._v("*")])])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"city","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"city","loading":_vm.loading,"clearable":false,"options":_vm.cityOptions,"reduce":function (val) { return val.value; },"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.city),callback:function ($$v) {_vm.city=$$v},expression:"city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Town/Area","label-for":"town"}},[_c('validation-provider',{attrs:{"name":"town","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"town","loading":_vm.loading,"clearable":false,"options":_vm.townOptions,"reduce":function (val) { return val.value; },"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.town),callback:function ($$v) {_vm.town=$$v},expression:"town"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"d-flex my-1 mt-2 justify-content-between align-items-center"},[_c('div',[_c('b-button',{staticClass:"py-1",attrs:{"type":"submit","variant":"primary","block":""}},[_vm._v(" Proceed "),_c('feather-icon',{attrs:{"icon":"ChevronRightIcon"}})],1)],1),_c('b-card-text',{staticClass:"text-right mt-0 text-danger cursor-pointer",on:{"click":_vm.logout}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"size":"16","icon":"LogOutIcon"}}),_c('span',[_vm._v("Logout")])],1)],1)],1)],1)],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }