<template>
<b-overlay rounded="sm" :show="loading">
  <AuthWrapper>
    <template #card>
      <div class="text-center">
        <div>
          <h1>Final Step</h1>
        </div>
        <div class="my-2">
          <p>Please provide the information below</p>
        </div>
      </div>
      <b-alert variant="danger" show>
        <div v-if="errorMessage" class="alert-body font-small-2">
          <b-card-text>
            <feather-icon icon="AlertCircleIcon" />
            <small>{{ errorMessage }}</small>
          </b-card-text>
        </div>
      </b-alert>
      <div>
        <validation-observer ref="residentialForm">
          <b-form @submit.prevent="saveOnboardingInfo">
            <b-media
              no-body
              vertical-align="center"
              class="flex-column flex-md-row"
            >
              <b-media-body>
                <div class="d-inline-block">
                  <validation-provider
                    #default="{ errors }"
                    name="Logo Image"
                    :rules="{
                      required: false,
                      size: 5000,
                    }"
                  >
                    <b-form-group
                      label="Company Logo"
                      label-for="logo-file"
                    >
                      <template #label>
                        <span>
                          Company Logo
                          <b-card-text class="my-50"></b-card-text>
                          <small class="text-muted">Image size limit 5mb.</small>
                        </span>
                      </template>

                      <b-form-file
                        id="logo-file"
                        ref="refInputEl"
                        v-model="companyLogo"
                        accept=".jpg, .png, .gif"
                        placeholder="Choose file"
                        :state="errors.length > 0 ? false : null"
                        @input="onImageRenderer"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </div>
              </b-media-body>

              <b-media-aside v-if="companyLogoPath">
                <b-img
                  :src="companyLogoPath"
                  height="120"
                  width="120"
                  class=""
                />
              </b-media-aside>
            </b-media>

            <b-form-group label="Company Name" label-for="company_name">
              <template #label>
                <span>Company Name<span style="color: tomato">*</span></span>
              </template>
              
              <validation-provider #default="{ errors }" name="company_name" rules="required">
                <div class="exxtra-input">
                  <span class="leading">
                    <feather-icon icon="BriefcaseIcon" size="20" />
                  </span>
                  <input id="company_name" v-model="company_name" placeholder="Enter your Company Name Here">
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="First Name" label-for="first_name">
              <template #label>
                <span>First Name<span style="color: tomato">*</span></span>
              </template>
              
              <validation-provider #default="{ errors }" name="first_name" rules="required">
                <div class="exxtra-input">
                  <span class="leading">
                    <feather-icon icon="UserIcon" size="20" />
                  </span>
                  <input id="first_name" v-model="first_name" placeholder="Enter your First Name Here">
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Last Name" label-for="last_name">
              <template #label>
                <span>Last Name<span style="color: tomato">*</span></span>
              </template>

              <validation-provider #default="{ errors }" name="last_name" rules="required">
                <div class="exxtra-input">
                  <span class="leading">
                    <feather-icon icon="UserIcon" size="20" />
                  </span>
                  <input id="last_name" v-model="last_name" placeholder="Enter your Last Name Here">
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Gender" label-for="gender" class="mt-2">
              <template #label>
                <span>Gender<span style="color: tomato">*</span></span>
              </template>

              <validation-provider #default="{ errors }" name="gender" rules="required">
                <div class="exxtra-input">
                  <span class="leading">
                    <feather-icon icon="HelpCircleIcon" size="20" />
                  </span>
                  <b-form-select id="gender" v-model="gender" :options="genderOptions" style="height: 3.3rem" />
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Date of Birth" label-for="date_of_birth">
              <template #label>
                <span>Date of Birth<span style="color: tomato">*</span></span>
              </template>

              <validation-provider #default="{ errors }" name="date_of_birth" rules="required">
                <div class="exxtra-input">
                  <span class="leading">
                    <feather-icon icon="CalendarIcon" size="20" />
                  </span>
                  <input id="date_of_birth" v-model="date_of_birth" placeholder="Enter your Date of birth Here" type="date">
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="TIN" label-for="tin">
              <template #label>
                <span>TIN<span style="color: tomato">*</span></span>
              </template>
              
              <validation-provider #default="{ errors }" name="tin" rules="required|length:15">
                <div class="exxtra-input">
                  <span class="leading">
                    <feather-icon icon="ClipboardIcon" size="20" />
                  </span>
                  <input id="tin" v-model="tin" placeholder="Enter your TIN Here">
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>


            <b-form-group label="Country" label-for="country">
              <template #label>
                <span>Country<span style="color: tomato">*</span></span>
              </template>

              <validation-provider #default="{ errors }" name="country" rules="required">
                <v-select
                  id="country"
                  v-model="country"
                  :loading="loading"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="countryOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Region/State/Province" label-for="region">
              <template #label>
                <span>Region/State/Province<span style="color: tomato">*</span></span>
              </template>

              <validation-provider #default="{ errors }" name="region" rules="required">
                <v-select
                  id="region"
                  v-model="region"
                  :loading="loading"             
                  :clearable="false"
                  :options="regionOptions"
                  :reduce="val => val.value"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="City" label-for="city">
              <template #label>
                <span>City<span style="color: tomato">*</span></span>
              </template>

              <validation-provider #default="{ errors }" name="city" rules="required">
                <v-select
                  id="city"
                  v-model="city"
                  :loading="loading"             
                  :clearable="false"
                  :options="cityOptions"
                  :reduce="val => val.value"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Town/Area" label-for="town">
              <validation-provider #default="{ errors }" name="town" rules="">
                <v-select
                  id="town"
                  v-model="town"
                  :loading="loading"             
                  :clearable="false"
                  :options="townOptions"
                  :reduce="val => val.value"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <div class="d-flex my-1 mt-2 justify-content-between align-items-center">
              <div>
                <b-button type="submit" variant="primary" block class="py-1">
                  Proceed
                  <feather-icon icon="ChevronRightIcon" />
                </b-button>
              </div>

              <b-card-text class="text-right mt-0 text-danger cursor-pointer" @click="logout">
                <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
                <span>Logout</span>
              </b-card-text>
            </div>

          </b-form>
        </validation-observer>
      </div>
    </template>
  </AuthWrapper>
</b-overlay>
</template>

<script>
import { get } from "lodash";
import { required } from '@validations';
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import vSelect from 'vue-select'
import AuthWrapper from "@core/components/auth-wrapper/AuthWrapper.vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import {
  BCol,
  BImg,
  BRow,
  BForm,
  BLink,
  BAlert,
  BMedia,
  BButton,
  BOverlay,
  BCardText,
  BFormFile,
  BCardTitle,
  BFormInput,
  BMediaBody,
  BMediaAside,
  BFormGroup,
  BFormSelect,
  BFormDatepicker
} from 'bootstrap-vue'

export default {
  components: {
    AuthWrapper,
    BOverlay,
    BAlert,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BMedia,
    BButton,
    vSelect,
    BCardText,
    BFormFile,
    BCardTitle,
    BMediaBody,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BMediaAside,
    BFormDatepicker,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      loading: false,
      errorMessage: "",
      hasErrorMessage: false,

      company_name: '',
      first_name: '',
      last_name: '',
      gender: '',
      tin: '',
      date_of_birth: '',
      genderOptions: [
        {
          value: "female",
          text: "Female"
        },
        {
          value: "male",
          text: "Male"
        }
      ],

      country: 2300660,
      region: '',
      city: '',
      town: '',

      countryOptions: [],
      regionOptions: [],
      cityOptions: [],
      townOptions: [],

      companyLogo: null,
      companyLogoPath: "",

      // validation
      required,
    }
  },
  watch: {
    country: {
      handler(changed){
        if (changed){
          this.fetchGeoData(changed, this.onRegionsFetch);
        }
      },
      immediate: true
    },
    region: {
      handler(changed){
        if (changed){
          this.fetchGeoData(changed, this.onCitiesFetch);
        }
      },
      immediate: true
    },
    city: {
      handler(changed){
        if (changed){
          this.fetchGeoData(changed, this.onTownsFetch);
        }
      },
      immediate: true
    }
  },
  created() {
    this.fetchCountries()
  },
  mounted() {
    this.first_name = this.getValueFromSource(this.currentUser, 'first_name');
    this.last_name = this.getValueFromSource(this.currentUser, 'last_name');
    this.gender = this.getValueFromSource(this.currentUser, 'gender');
    this.date_of_birth = this.getValueFromSource(this.currentUser, 'date_of_birth');
    this.tin = this.getValueFromSource(this.currentUser, 'tin');

    this.country = this.getValueFromSource(this.currentUser, 'country', 2300660);
    this.region = this.getValueFromSource(this.currentUser, 'region');
    this.city = this.getValueFromSource(this.currentUser, 'city');
    this.town = this.getValueFromSource(this.currentUser, 'town');
  },
  methods: {
    async fetchCountries() {
      try {
        this.loading = true;

        const request = await this.useJwt().sharedService.fetchCountries({ criteria: 'all' });
        const { data } = request.data;
        this.countryOptions = data.map(country => ({
          label: country.country,
          value: country.geo_name_id
        }));

        this.region = '';
        this.city = '';
        this.town = '';
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchGeoData(geo_id, cb) {
      try {
        this.loading = true;

        const request = await this.useJwt().sharedService.fetchGeoData({ geo_id });
        const { data } = request.data;
        cb(data)
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async saveOnboardingInfo() {
      try {
        this.loading = true;
        
        const success = await this.$refs.residentialForm.validate();
        if (!success) {
          return;
        }

        const {
          first_name,
          last_name,
          gender,
          date_of_birth,
          tin,
          country,
          region,
          city,
          town,
        } = this;
        
        const formData = new FormData()

        formData.append("company_name", this.company_name);
        formData.append("first_name", first_name);
        formData.append("last_name", last_name);
        formData.append("gender", gender);
        formData.append("date_of_birth", date_of_birth);
        formData.append("country", country);
        formData.append("region", region);
        formData.append("city", city);
        formData.append("town", town);
        formData.append("tin", tin);

        if (this.companyLogo){
          formData.append('company_logo', this.companyLogo)
        }

        await this.useJwt().authService.saveProviderOnboardingData(formData);

        this.$router.replace({
            name: "provider-home"
          })
          .catch(() => {});

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success!',
            icon: 'AlertQuestionIcon',
            variant: 'success',
            text: `Welcome to Pentecost Job Search App. Kindly complete setting up your business from the company profile page.`
          },
        }, {
          timeout: 60000
        });
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.errorMessage = error_message

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    onRegionsFetch(data) {
      this.cityOptions = [];
      this.townOptions = [];
      this.region = '';
      this.city = '';
      this.town = '';

      this.regionOptions = data.map(geo_data => ({
        label: geo_data.name,
        value: geo_data.geonameId
      }));
    },
    onCitiesFetch(data) {
      this.townOptions = [];
      this.city = '';
      this.town = '';

      this.cityOptions = data.map(geo_data => ({
        label: geo_data.name,
        value: geo_data.geonameId
      }));
    },
    onTownsFetch(data) {
      this.town = '';
      this.townOptions = data.map(geo_data => ({
        label: geo_data.name,
        value: geo_data.geonameId
      }));
    },
    onImageRenderer(file) {
     const reader = new FileReader()

      reader.addEventListener(
        'load',
        () => {
          this.companyLogoPath = reader.result
          this.companyLogo = file;
        },
        false,
      )

      if (file) {
        reader.readAsDataURL(file)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
